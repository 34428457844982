<i18n>
{
  "de": {
    "pageTitle": "Benutzer hinzufügen",
    "roleLabel": "Berechtigung",
    "userOptionsLabel": "Benutzer",
    "submitButtonLabel": "Speichern",
    "portfolioNotFound": "Portfolio nicht gefunden",
    "roleLabels": {
      "USER": "USER",
      "MANAGER": "MANAGER"
    }
  }
}
</i18n>

<template>
  <MainLayout :ready="portfolioReady && allUsersReady" :portfolio="portfolio" class="admin-add-user-to-portfolio-page">
    <template #default>
      <p v-if="error">{{ JSON.stringify(error) }}</p>
      <h1>{{ $t('pageTitle') }}</h1>
      <form>
        <div class="form-field">
          <label for="user-options">{{ $t('userOptionsLabel') }}</label>
          <div>
            <Select id="user-options" v-model="userId" clearable :options="userOptions" :reduce="(user) => user.id" />
          </div>
        </div>

        <div class="form-field">
          <label for="role-options">{{ $t('roleLabel') }}</label>
          <div>
            <v-select id="role-options" v-model="role" clearable :options="roleOptions" :reduce="(role) => role.key" />
          </div>
        </div>

        <ButtonWrapper>
          <button type="button" class="button" :disabled="!submitButtonEnabled" @click="onSubmit">
            {{ $t('submitButtonLabel') }}
          </button>
        </ButtonWrapper>
      </form>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'

import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import PortfolioUsersMixin from '@/pages/vuex-mixins/PortfolioUsersMixin.vue'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import Select from '@/components/shared/forms/Select.vue'

export default {
  name: 'adminAddUserToPortfolio',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioUsersMixin, // Provides: portfolio.users, portfolioUsersLoading, portfolioUsersReady
    AdminAllUsersMixin, // Provides: allUsers, allUsersReady
  ],

  components: {
    ButtonWrapper,
    MainLayout,
    Select,
  },

  data() {
    return {
      userId: null,
      role: null,
      error: null,
    }
  },

  computed: {
    roleOptions() {
      return [
        {
          key: 'USER',
          label: this.$t('roleLabels.USER'),
        },
        {
          key: 'MANAGER',
          label: this.$t('roleLabels.MANAGER'),
        },
      ]
    },

    portfolioUsers() {
      return this.portfolio && this.portfolio.users
    },

    userOptions() {
      const users = this.allUsers || []
      const existingUsers = this.portfolioUsers || []
      return users
        .filter((user) => !existingUsers.find((eu) => eu.id === user.id))
        .map((user) => {
          return { label: `${user.first_name} ${user.last_name} (${user.company_name})`, id: user.id }
        })
    },

    submitButtonEnabled() {
      return Boolean(this.userId) && this.role !== null
    },
  },

  methods: {
    ...mapActions({
      addPortfolioUser: 'portfolio/addPortfolioUser',
    }),

    async onSubmit() {
      try {
        this.error = null
        await this.addPortfolioUser({ portfolioId: this.portfolio_id, userId: this.userId, role: this.role })
        this.$router.push({ name: 'adminPortfolioDetails', params: { portfolio_id: this.portfolio_id } })
      } catch (error) {
        this.error = error
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-add-user-to-portfolio-page {
  & form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    align-items: stretch;
    justify-content: flex-start;

    & .form-field {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      gap: var(--spacing-m);

      & label {
        width: 160px;
      }

      & > div {
        width: 220px;
      }
    }

    & button {
      grid-column-end: -1;
      justify-self: start;
    }
  }
}
</style>
